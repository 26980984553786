<template>
  <b-col
    ref="metadata"
    cols="12"
    md="8"
  >
    <b-card>
      <b-tabs>
        <b-tab>
          <template #title>
            <feather-icon icon="MapIcon" />
            <span>Address</span>
          </template>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <addresses
                :user-address="userAddress"
                :add-component-name="addComponentName"
                :edit-component-name="editComponentName"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          v-if="haseFiles"
          id="files"
        >
          <template
            #title
          >
            <feather-icon icon="FileIcon" />
            <span>Documents</span>
          </template>
          <!-- <files /> -->
          <zero-files
            :user-files="userFiles"
            :columns2="columns2"
            :options="options"
            :loader="loader"
          />
        </b-tab>
        <b-tab
          v-if="hasTransaction"
          id="tarsnactions"
        >
          <template
            #title
          >
            <feather-icon icon="GitPullRequestIcon" />
            <span>Transactions</span>
          </template>
          <!-- <files /> -->
          <user-transactions />
        </b-tab>
        <b-tab
          v-if="hasOrders"
          id="Orders"
        >
          <template
            #title
          >
            <feather-icon icon="ShoppingCartIcon" />
            <span>Orders</span>
          </template>
          <z-orders />
        </b-tab>
        <b-tab
          v-if="hasWalletTransaction"
          id="Wallet"
        >
          <template
            #title
          >
            <feather-icon icon="CreditCardIcon" />
            <span>Wallet Transactions</span>
          </template>
          <ZWalletTransactions />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-col>
</template>

<script>
import addresses from '@/views/z-addresses/ZeroAddress.vue'
import files from '@/views/z-files/AddEditFile.vue'
import ZeroFiles from '@/views/z-files/ZeroFiles.vue'
import UserTransactions from '@/views/z-taransactions/UserTransactions.vue'
import ZOrders from '@/views/z-orders/ZOrders.vue'
import ZWalletTransactions from '@/views/z-wallet-taransactions/WalletTransactions.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    files,
    addresses,
    ZeroFiles,
    UserTransactions,
    ZOrders,
    ZWalletTransactions,
  },
  props: {
    userAddress: {
      type: Array,
      default: () => [],
    },
    userFiles: {
      type: Array,
      default: () => [],
    },
    userTransactions: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    haseFiles: {
      type: Boolean,
      default: () => true,
    },
    hasTransaction: {
      type: Boolean,
      default: () => true,
    },
    hasWalletTransaction: {
      type: Boolean,
      default: () => true,
    },
    hasOrders: {
      type: Boolean,
      default: () => true,
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    editComponentName: {
      type: String,
      default: () => '',
    },
    loader: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const columns2 = [
      { key: 'id', sortable: true },
      { key: 'type', label: 'Type' },
      { key: 'actions' },
    ]
    return {
      columns2,
    }
  },
}
</script>

<style scoped>
.padd span {
  padding: 2px;
}
</style>
