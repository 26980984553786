<template>
  <div class="neg-margin">
    <general-table
      ref="transactionTable"
      :api-url="apiUrl"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :type="type"
      :columns="columns"
      :guard="guard"
      :search-box="false"
      :export-table="false"
      :viw-component="viwComponent"
      :selectable="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },

  setup() {
    const { router } = useRouter()
    const transactions = ref([])

    const columns = [
      { key: 'id', label: 'Id' },
      { key: 'type', label: 'Type' },
      { key: 'amount', label: 'Amount' },
      { key: 'method', label: 'Method' },

    ]

    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      transactions,
      columns,
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
      router,
    }
  },
  computed: {
    apiUrl() {
      let endPoint = ''
      if (this.router.currentRoute.path.includes('merchants')) {
        endPoint = 'merchants'
      } else if (this.router.currentRoute.path.includes('users')) {
        endPoint = 'user'
      } else {
        endPoint = 'branches'
      }
      const base = `${endPoint}/walletHistory/${this.$route.params.id}`

      return base
    },
  },
  mounted() {
  },
  methods: {

    // eslint-disable-next-line consistent-return
    timeAgo(input) {
      const date = (input instanceof Date) ? input : new Date(input)
      const formatter = new Intl.RelativeTimeFormat('en')
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      }
      const secondsElapsed = (date.getTime() - Date.now()) / 1000
      // eslint-disable-next-line no-restricted-syntax
      for (const key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key]
          return formatter.format(Math.round(delta), key)
        }
      }
    },
  },

}
</script>

<style>
.neg-margin{
  margin: -1.5rem;
}
</style>
